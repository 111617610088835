import React, { useMemo, useState } from 'react';

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Autocomplete from '@mui/material/Autocomplete';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { usePlatesByType } from 'client/app/api/PlateTypesApi';
import { useInputLiquids } from 'client/app/apps/workflow-builder/lib/useElementContext';
import { ParameterHeader } from 'client/app/components/Parameters/ElementParameterHeader';
import CollapsibleParameter from 'client/app/components/Parameters/PlateLayout/CollapsibleParameter';
import Help from 'client/app/components/Parameters/PlateLayout/Help';
import { usePlateLayoutEditorContext } from 'client/app/components/Parameters/PlateLayout/PlateLayoutEditorContext';
import { formatLayerAutomaticName } from 'client/app/components/Parameters/PlateLayout/plateLayoutUtils';
import PlateSelectionEditor from 'client/app/components/Parameters/PlateType/PlateSelectionEditor';
import { PlateTypeSelect } from 'client/app/components/Parameters/PlateType/PlateTypeSelect';
import { PlateParameterValue } from 'client/app/components/Parameters/PlateType/processPlateParameterValue';
import {
  NamingMode,
  PlateAssignmentMode,
  WellSortMode,
} from 'common/types/plateAssignments';
import ConfirmationDialog from 'common/ui/components/Dialog/ConfirmationDialog';
import IntegerEditor from 'common/ui/components/ParameterEditors/IntegerEditor';
import MeasurementEditor from 'common/ui/components/ParameterEditors/MeasurementEditor';
import { getSensibleMeasurementUnits } from 'common/ui/components/ParameterEditors/unitRegistry';
import Switch from 'common/ui/components/Switch';
import Toggle, { ToggleButton } from 'common/ui/components/Toggle/Toggle';
import Dropdown, { Option } from 'common/ui/filaments/Dropdown';
import useDialog from 'common/ui/hooks/useDialog';
import LiquidsIcon from 'common/ui/icons/LiquidsIcon';

export default function PlateSetup() {
  const {
    plateAssignment,
    isReadonly,
    plateAssignment: { plateType },
    setPlateType,
    setPlateAssignmentMode,
    setReplicates,
    setTotalVolume,
    setDiluentName,
    setLayerSortMode,
    setNamingMode,
    setNamingPlateLayerId,
    liquidParameters,
    isMixOnto,
    plateLayers,
  } = usePlateLayoutEditorContext();

  const volumeUnits = getSensibleMeasurementUnits('Volume');
  const {
    inputLiquids: [inputLiquids],
  } = useInputLiquids(liquidParameters.inputLiquids);
  const sortMode =
    plateAssignment.plateLayers[plateAssignment.plateLayers.length - 1]?.wellSets[0]
      ?.sortBy ?? undefined;

  const [confirmSwitchModeDialog, openConfirmSwitchModeDialog] =
    useDialog(ConfirmationDialog);
  const [confirmSwitchPlateDialog, openConfirmSwitchPlateDialog] =
    useDialog(ConfirmationDialog);

  const [platesByType] = usePlatesByType();

  const handleSwitchPlate = async (
    plateParameterValue: PlateParameterValue | undefined,
  ) => {
    const hasLiquidsOrLayersDefined =
      plateAssignment.plateLayers.length > 1 ||
      plateAssignment.plateLayers.some(layer => layer.wellSets.length > 0);

    const hasClearedPlate = plateParameterValue === undefined;
    const hasChangedPlate = plateParameterValue !== plateAssignment.plateType;

    // User haven't changed anything
    if (!hasChangedPlate) return;

    // User is setting a plate for the first time, or is changing or clearing plates
    // when they have no liquids or layers defined, so we can safely switch plates.
    if (!hasLiquidsOrLayersDefined) {
      setPlateType(plateParameterValue);
      return;
    }

    // User has cleared the plate when they have some liquids or layers defined.
    if (hasClearedPlate) {
      const isConfirmed = await openConfirmSwitchPlateDialog({
        action: 'clear',
        isActionDestructive: true,
        object: 'plate type',
        additionalMessage:
          'This will result in your liquids and layers being removed. This cannot be undone.',
      });
      isConfirmed && setPlateType(plateParameterValue, true);
      return;
    }

    // User has changed plate when they have some liquids or layers defined.
    // We will check based on plateLayers if the new plate matches the dimensions to allow changing.
    if (plateParameterValue && hasChangedPlate) {
      const { columns: columnsOnSelectedPlate, rows: rowsOnSelectedPlate } =
        typeof plateParameterValue === 'string'
          ? platesByType(plateParameterValue)
          : platesByType(plateParameterValue.item.type);

      const [highestColumnIndex, highestRowIndex] = plateAssignment.plateLayers
        .map(layer => layer.wellSets.map(wellSet => wellSet.wells))
        .flat(2)
        .reduce(
          (acc, well) => [
            well.x > acc[0] ? well.x : acc[0],
            well.y > acc[1] ? well.y : acc[1],
          ],
          [-1, -1],
        );
      const columnsBasedOnLayers = highestColumnIndex + 1;
      const rowsBasedOnLayers = highestRowIndex + 1;

      const platesMatchDimensions =
        columnsOnSelectedPlate >= columnsBasedOnLayers &&
        rowsOnSelectedPlate >= rowsBasedOnLayers;

      // Dimentions match, so fine to set plate but don't reset liquids and layers.
      if (platesMatchDimensions) {
        setPlateType(plateParameterValue);
        return;
      }

      // Otherwise check with user if they want to change to the plate with wrong dimentions.
      const isConfirmed = await openConfirmSwitchPlateDialog({
        action: 'change',
        isActionDestructive: true,
        object: 'plate type',
        additionalMessage: `The selected plate type has a different layout of wells (${rowsOnSelectedPlate}x${columnsOnSelectedPlate}) than the existing plate type (at least ${rowsBasedOnLayers}x${columnsBasedOnLayers}), meaning your liquids cannot be mapped over and you liquids and layers will be removed. This cannot be undone.`,
      });
      isConfirmed && setPlateType(plateParameterValue, true);
    }
  };

  const handleSwitchMode = async (plateAssignmentMode: PlateAssignmentMode | null) => {
    if (plateAssignmentMode === null) {
      return; // don't allow complete deselection of the mode
    }
    if (
      plateAssignment.plateLayers.length > 1 ||
      plateAssignment.plateLayers.some(layer => layer.wellSets.length > 0)
    ) {
      const isConfirmed = await openConfirmSwitchModeDialog({
        action: 'switch',
        isActionDestructive: true,
        object: 'mixing mode',
        additionalMessage:
          'This will result in your liquids and layers being removed. This cannot be undone.',
      });
      if (!isConfirmed) {
        return;
      }
      setPlateAssignmentMode(plateAssignmentMode, true);
    } else {
      setPlateAssignmentMode(plateAssignmentMode);
    }
  };

  const layerForNamingOptions = useMemo<Option<string>[]>(() => {
    if (plateAssignment.namingMode !== NamingMode.KEEP_NAME_OF_SPECIFIC_LAYER) {
      return [];
    }
    return plateLayers.map(layer => {
      return { label: formatLayerAutomaticName(layer.id, plateLayers), value: layer.id };
    });
  }, [plateAssignment.namingMode, plateLayers]);

  const layerForNamingOptionValueLabel =
    layerForNamingOptions.find(
      layerOption => layerOption.value === plateAssignment.namingPlateLayerID,
    )?.label ?? '';

  // By default we will hide these because it is valid for them to be left undefined
  // and the confuse the user. The only time we show them is if the user has defined them
  // or if they have any concentrations set for liquids targets (in which case, they will
  // have to set these values)
  const totalVolumeOrDiluentDefined =
    !!plateAssignment.totalVolume || !!plateAssignment.diluentName;
  const someLiquidTargetsSetAsConcentrations = useMemo(() => {
    return plateLayers
      .flatMap(layer => layer.liquids)
      .some(liquid => {
        return 'concentration' in liquid.target;
      });
  }, [plateLayers]);
  const showTotalVolumeAndDiluent =
    totalVolumeOrDiluentDefined || someLiquidTargetsSetAsConcentrations;

  const [showTotalVolumeAndDiluentToggleState, setShowTotalVolumeAndDiluentToggleState] =
    useState(showTotalVolumeAndDiluent);

  const isDisabled = isReadonly || isMixOnto;
  return (
    <Wrapper>
      <div>
        <CollapsibleParameter in>
          <ParameterHeader
            displayName="Plate Type"
            isRequired
            help={Help.plateTypeHelp}
          />
          <PlateSelectionEditor
            value={plateType ?? null}
            onChange={handleSwitchPlate}
            isDisabled={isDisabled}
            plateSelectors={[PlateTypeSelect]}
          />
        </CollapsibleParameter>
        <CollapsibleParameter in={!!plateAssignment.plateType && !isMixOnto}>
          <ParameterHeader
            displayName="Mixing Mode"
            isRequired
            help={Help.assignmentModeHelp}
          />
          <Toggle
            value={plateAssignment.assignmentMode}
            onChange={(_, value) => handleSwitchMode(value)}
            exclusive
            disabled={isReadonly || !plateAssignment.plateType}
          >
            <ToggleButton value="descriptive">Descriptive</ToggleButton>
            <ToggleButton value="combinatorial">Combinatorial</ToggleButton>
          </Toggle>
        </CollapsibleParameter>
        <Collapse in={!!plateAssignment.plateType}>
          <StyledDivider orientation="horizontal" />
          <StyledSwitchContainer>
            <Typography variant="subtitle2">Top up with diluent</Typography>
            <Switch
              checked={showTotalVolumeAndDiluentToggleState}
              onChange={(_, checked) => setShowTotalVolumeAndDiluentToggleState(checked)}
              disabled={showTotalVolumeAndDiluent || isReadonly}
              size="small"
            />
          </StyledSwitchContainer>
          {someLiquidTargetsSetAsConcentrations && (
            <CaptionText variant="caption">
              Some liquids are set by concentration, so final target volume (and
              optionally a diluent) need to be specified.
            </CaptionText>
          )}
        </Collapse>
        <CollapsibleParameter
          in={!!plateAssignment.plateType && showTotalVolumeAndDiluentToggleState}
        >
          <ParameterHeader
            displayName="Final Target Volume"
            help={Help.finalTargetVolumeHelp}
            isRequired={someLiquidTargetsSetAsConcentrations}
          />
          <MeasurementEditor
            onChange={value => setTotalVolume(value)}
            validUnits={volumeUnits}
            defaultUnit="ul"
            value={plateAssignment.totalVolume}
            isDisabled={isReadonly || !plateAssignment.plateType}
            placeholder="Volume"
          />
        </CollapsibleParameter>
        <CollapsibleParameter
          in={!!plateAssignment.plateType && showTotalVolumeAndDiluentToggleState}
        >
          <ParameterHeader displayName="Diluent Name" help={Help.diluentNameHelp} />
          <Autocomplete
            value={plateAssignment.diluentName}
            freeSolo
            size="small"
            options={(inputLiquids ?? [])?.map(liquid => liquid.name)}
            onInputChange={(_, option) => setDiluentName(option)}
            disabled={isReadonly}
            renderInput={params => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: <LiquidsIcon />,
                  placeholder: 'Liquid Name',
                }}
              />
            )}
          />
        </CollapsibleParameter>

        <CollapsibleParameter in={!!plateAssignment.plateType}>
          <StyledDivider orientation="horizontal" />
          <ParameterHeader displayName="Naming Mode" help={Help.namingModeHelp} />
          <Toggle
            value={plateAssignment.namingMode}
            onChange={(_, value) => setNamingMode(value)}
            exclusive
            disabled={isReadonly || !plateAssignment.plateType}
          >
            <ToggleButton value={NamingMode.NONE}>Default</ToggleButton>
            <ToggleButton value={NamingMode.KEEP_NAME_OF_SPECIFIC_LAYER}>
              By Layer
            </ToggleButton>
          </Toggle>
        </CollapsibleParameter>

        <CollapsibleParameter
          in={
            !!plateAssignment.plateType &&
            plateAssignment.namingMode === NamingMode.KEEP_NAME_OF_SPECIFIC_LAYER
          }
        >
          <ParameterHeader
            displayName="Layer For Naming"
            help={Help.namingPlateLayerIDHelp}
            isRequired
          />
          <Dropdown
            placeholder="Layer"
            valueLabel={layerForNamingOptionValueLabel}
            options={layerForNamingOptions}
            onChange={layerId => {
              layerId && setNamingPlateLayerId(layerId);
            }}
            isDisabled={isReadonly || !plateAssignment.plateType}
            isRequired
          />
        </CollapsibleParameter>

        <CollapsibleParameter
          in={plateAssignment.assignmentMode === PlateAssignmentMode.COMBINATORIAL}
        >
          <ParameterHeader
            displayName="Replicates"
            isRequired
            help={Help.replicatesHelp}
          />
          <IntegerEditor
            value={plateAssignment.replicates}
            onChange={newValue => newValue && setReplicates(newValue)}
            isDisabled={isReadonly}
            placeholder="Number of Replicates"
            type=""
          />
        </CollapsibleParameter>
        <CollapsibleParameter
          in={plateAssignment.assignmentMode === PlateAssignmentMode.COMBINATORIAL}
        >
          <ParameterHeader
            displayName="Layout"
            isRequired
            help={Help.wellSetSortByHelp}
          />
          <StyledToggle
            value={sortMode}
            onChange={(_, value) => setLayerSortMode(value as WellSortMode)}
            exclusive
            disabled={isReadonly}
          >
            <ToggleButton value={WellSortMode.BY_ROW}>
              <ArrowForwardIcon />
            </ToggleButton>
            <ToggleButton value={WellSortMode.BY_COLUMN}>
              <ArrowDownwardIcon />
            </ToggleButton>
          </StyledToggle>
        </CollapsibleParameter>
        {confirmSwitchModeDialog}
        {confirmSwitchPlateDialog}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(6),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  overflowX: 'hidden',
}));

const StyledToggle = styled(Toggle)({
  width: 'min-content',
});

const StyledSwitchContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const StyledDivider = styled(Divider)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  marginTop: theme.spacing(3),
}));

const CaptionText = styled(Typography)(({ theme }) => ({
  display: 'inline-block',
  textAlign: 'justify',
  color: theme.palette.info.dark,
}));
