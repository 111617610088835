import { getRuleMeta, RuleMetaKey } from 'client/app/apps/policy-library/RulesMeta';
import { capitalize, getOrderOperatorEncoding } from 'common/lib/format';

type ParsedParameterValue = { isValid: boolean; parsed: string[] }[];

export enum ConditionParameter {
  CHANNEL_MAX_FLOW_RATE = 'channel_max_flow_rate',
  CHANNEL_MAX_VOLUME = 'channel_max_volume',
  CHANNEL_MIN_FLOW_RATE = 'channel_min_flow_rate',
  CHANNEL_MIN_VOLUME = 'channel_min_volume',
  CHANNEL_TIP_TYPE = 'channel_tip_type',
  DESTINATION_FINAL_VOLUME = 'destination_final_volume',
  DESTINATION_INITIAL_VOLUME = 'destination_initial_volume',
  DESTINATION_MAX_VOLUME = 'destination_maxvolume',
  DESTINATION_PLATE_TYPE = 'destination_plate_type',
  DESTINATION_RESIDUAL = 'destination_residual',
  DESTINATION_WELL_SIZE_X = 'destination_well_size_x',
  DESTINATION_WELL_SIZE_Y = 'destination_well_size_y',
  DESTINATION_WELL_SIZE_Z = 'destination_well_size_z',
  DEVICE_MANUFACTURER = 'device_manufacturer',
  DEVICE_MODEL = 'device_model',
  LIQUID_TYPE = 'liquid_type',
  SAMPLE_ID = 'sample_id',
  SOURCE_FINAL_VOLUME = 'source_final_volume',
  SOURCE_INITIAL_VOLUME = 'source_initial_volume',
  SOURCE_MAX_VOLUME = 'source_max_volume',
  SOURCE_PLATE_TYPE = 'source_plate_type',
  SOURCE_RESIDUAL = 'source_residual',
  SOURCE_WELL_SIZE_X = 'source_well_size_x',
  SOURCE_WELL_SIZE_Y = 'source_well_size_y',
  SOURCE_WELL_SIZE_Z = 'source_well_size_z',
  TRANSFER_VOLUME = 'transfer_volume',
}

export class ConditionParser {
  private parameter: ConditionParameter;
  private formatRegex: RegExp;
  private allowedUnits?: string[];

  constructor(
    parameter: ConditionParameter,
    allowedUnits: string[] | undefined,
    logicalOperators: string[] = ['>=', '<=', '>', '<', '='],
  ) {
    const unitsPattern = allowedUnits ? allowedUnits.join('|') : '';
    const positiveFloatPattern = '\\d+(\\.\\d+)?';
    const logicalOperatorsPattern = logicalOperators
      // escaping logical expressions to form a regex
      .map(op => op.replace(/([>=<])/g, '\\$1'))
      .join('|');

    const regexPattern = allowedUnits
      ? `^(${logicalOperatorsPattern})\\s*(${positiveFloatPattern})\\s*(${unitsPattern})$`
      : `^(${logicalOperatorsPattern})\\s*(${positiveFloatPattern})$`;

    this.parameter = parameter;
    this.allowedUnits = allowedUnits;
    this.formatRegex = new RegExp(regexPattern);
  }

  parse(value: string) {
    switch (this.parameter) {
      case ConditionParameter.CHANNEL_MAX_FLOW_RATE:
      case ConditionParameter.CHANNEL_MAX_VOLUME:
      case ConditionParameter.CHANNEL_MIN_FLOW_RATE:
      case ConditionParameter.CHANNEL_MIN_VOLUME:
      case ConditionParameter.DESTINATION_FINAL_VOLUME:
      case ConditionParameter.DESTINATION_INITIAL_VOLUME:
      case ConditionParameter.DESTINATION_MAX_VOLUME:
      case ConditionParameter.DESTINATION_RESIDUAL:
      case ConditionParameter.SOURCE_FINAL_VOLUME:
      case ConditionParameter.SOURCE_INITIAL_VOLUME:
      case ConditionParameter.SOURCE_MAX_VOLUME:
      case ConditionParameter.SOURCE_RESIDUAL:
      case ConditionParameter.TRANSFER_VOLUME: {
        return this.parseNumericWithUnits(value);
      }
      case ConditionParameter.DESTINATION_WELL_SIZE_X:
      case ConditionParameter.DESTINATION_WELL_SIZE_Y:
      case ConditionParameter.DESTINATION_WELL_SIZE_Z:
      case ConditionParameter.SOURCE_WELL_SIZE_X:
      case ConditionParameter.SOURCE_WELL_SIZE_Y:
      case ConditionParameter.SOURCE_WELL_SIZE_Z: {
        return this.parseNumericWithoutUnits(value);
      }
      case ConditionParameter.CHANNEL_TIP_TYPE:
      case ConditionParameter.DESTINATION_PLATE_TYPE:
      case ConditionParameter.DEVICE_MANUFACTURER:
      case ConditionParameter.DEVICE_MODEL:
      case ConditionParameter.SAMPLE_ID:
      case ConditionParameter.SOURCE_PLATE_TYPE: {
        return this.parseString(value);
      }
      default: {
        throw new Error(`Unsupported parameter: ${this.parameter}`);
      }
    }
  }

  private parseNumericWithUnits(value: string) {
    const parsedParameterValue = value.split('&').map(expresion => {
      const match = expresion.trim().match(this.formatRegex);

      if (!match) return { isValid: false, parsed: [] };

      return { isValid: true, parsed: [match[1], match[2], match[4]] };
    });

    if (this.isValid(parsedParameterValue)) {
      return parsedParameterValue
        .map(input => input.parsed)
        .map(parsed => ({
          variable: this.parameter,
          value: { value: Number(parsed[1]), unit: parsed[2] },
          operator: getOrderOperatorEncoding(parsed[0]),
        }));
    } else {
      throw new MeasurementConditionError(this.parameter, this.allowedUnits);
    }
  }

  private parseNumericWithoutUnits(value: string) {
    const parsedParameterValue = value.split('&').map(expresion => {
      const match = expresion.trim().match(this.formatRegex);

      if (!match) return { isValid: false, parsed: [] };

      return { isValid: true, parsed: [match[1], match[2]] };
    });

    if (this.isValid(parsedParameterValue)) {
      return parsedParameterValue
        .map(input => input.parsed)
        .map(parsed => ({
          variable: this.parameter,
          value: Number(parsed[1]),
          operator: getOrderOperatorEncoding(parsed[0]),
        }));
    } else {
      throw new MeasurementConditionError(this.parameter, undefined);
    }
  }

  private parseString(value: string) {
    const parsedParameterValue = value.split(';').map(s => s.trim());

    return [
      {
        variable: this.parameter,
        values: parsedParameterValue,
      },
    ];
  }

  private isValid(value: ParsedParameterValue) {
    return value.map(input => input.isValid).every(value => value);
  }
}

export class MeasurementConditionError extends Error {
  constructor(conditionVariable: string, allowedUnits: string[] | undefined) {
    super();
    this.message = this.buildMessage(conditionVariable, allowedUnits);
  }

  private buildMessage(conditionVariable: string, allowedUnits: string[] | undefined) {
    const ruleMeta = getRuleMeta('condition', conditionVariable as RuleMetaKey);
    const conditionLabel = capitalize(ruleMeta.label);
    const invalidFormatMessage = `Invalid format of condition "${conditionLabel}".`;
    const checkHeadeMessage =
      'Please make sure to check the help information available in the column header.';
    const requiredUnitsMessage = allowedUnits
      ? `It can only include SI prefixed units e.g. ${allowedUnits
          .slice(0, 3)
          .map(unit => `"${unit}"`)
          .join(', ')}.`
      : '';

    return `${invalidFormatMessage} ${checkHeadeMessage} ${requiredUnitsMessage}`;
  }
}
