import React from 'react';

import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';

import { Name } from 'client/app/apps/protocols/EditableName';
import Colors from 'common/ui/Colors';

type Props = {
  entry: string;
  tooltip: string;
  checked: boolean;
  onCheck: () => void;
  disabled: boolean;
};

export function InputStepItem({ entry, disabled, tooltip, checked, onCheck }: Props) {
  return (
    <Stack direction="row" justifyContent="space-around" alignItems="center" spacing={5}>
      <Tooltip title={tooltip}>
        <span>
          <StyledCheckbox
            edge="start"
            checked={checked}
            disabled={disabled}
            onClick={onCheck}
          />
        </span>
      </Tooltip>
      <Name>{entry}</Name>
    </Stack>
  );
}

const StyledCheckbox = styled(Checkbox)(() => ({
  padding: 0,
  marginLeft: 0.5,
  paddingLeft: 5,
  '&.Mui-disabled': {
    // slightly enhance emphasis that it is disabled
    color: Colors.GREY_20,
  },
}));
