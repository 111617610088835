import React, { useCallback, useContext, useMemo } from 'react';

import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import cx from 'classnames';

import ElementParameterGroupHeader from 'client/app/components/Parameters/ElementParameterGroupHeader';
import ParameterHeader from 'client/app/components/Parameters/ElementParameterHeader';
import ParameterEditor, {
  ParameterEditorHelperText,
} from 'client/app/components/Parameters/ParameterEditor';
import {
  PlateContentParams,
  useFilterPlateContentParameters,
} from 'client/app/components/Parameters/PlateContents/lib/plateContentsEditorUtils';
import { PLATE_BASED_MIXING_PARAMETER } from 'client/app/components/Parameters/PlateLayout/plateLayoutUtils';
import {
  ParameterState,
  ParameterStateRuleResult,
} from 'client/app/lib/rules/elementConfiguration/evaluateParameterState';
import { ParameterStateContext } from 'client/app/lib/rules/elementConfiguration/ParameterStateContext';
import { getParameterDisplayName } from 'client/app/lib/workflow/elementConfigUtils';
import { ConnectionMaskDict } from 'client/app/lib/workflow/types';
import { useWorkflowBuilderDispatch } from 'client/app/state/WorkflowBuilderStateContext';
import {
  AdditionalEditorProps,
  ArrayAdditionalProps,
} from 'common/elementConfiguration/AdditionalEditorProps';
import { EditorType } from 'common/elementConfiguration/EditorType';
import { sanitiseParameterValue } from 'common/elementConfiguration/parameterUtils';
import { useFeatureToggle } from 'common/features/useFeatureToggle';
import { groupBy } from 'common/lib/data';
import {
  Parameter,
  ParameterValue,
  ParameterValueDict,
  WorkflowConfig,
} from 'common/types/bundle';
import Colors from 'common/ui/Colors';
import ConnectionOnlyEditor from 'common/ui/components/ParameterEditors/ConnectionOnlyEditor';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type ParamChangeCallback = (
  paramName: string,
  value: ParameterValueDict,
  instanceName?: string,
) => void;

/**
 * Props needed at the InstanceParameter level that need to be passed in at the
 * ElementParameterGroupList level. Used by every component in this file.
 */
type GeneralProps = {
  onChange: ParamChangeCallback;
  onPendingChange?: ParamChangeCallback;
  elementId: string;
  /** Required by the PlateReaderProtocol editors. */
  workflowId?: string;
  /** Required by Table UI */
  workflowName?: string;
  workflowConfig: WorkflowConfig;
  instanceName: string;
  /** Used to determine which parameters have values provided through connections */
  connectionMaskDict?: ConnectionMaskDict;
  isDisabled?: boolean;
  defaultParameters: ParameterValueDict;
};

type Props = GeneralProps & {
  /** A list of parameters (information about the parameters, not values) */
  parameters: readonly Parameter[];
  /** A map of parameter names to to their values */
  parameterValueDict: ParameterValueDict;
  /** Evaluation results from the Element Configuration rule engine */
  ParameterStateRuleResult?: ParameterStateRuleResult;
  showValidation?: boolean;
};

function isParameterSet(parameterValue: ParameterValue) {
  return parameterValue !== null && parameterValue !== undefined;
}

export default function ElementParameterGroupList(props: Props) {
  const { areAllGroupParametersHidden } = useContext(ParameterStateContext);

  const groupedParams = useMemo(
    () =>
      Object.entries(
        groupBy(
          props.parameters
            // Remove any deprecated parameters that don't have values set.
            // We don't want them to factor into the logic behind whether groups
            // are hidden or not.
            .filter(
              parameter =>
                !(parameter.configuration?.isVisible === false) ||
                isParameterSet(props.parameterValueDict[parameter.name]),
            )
            .map(parameter => ({
              ...parameter,
              groupName: parameter.groupName ?? '',
            })),
          'groupName',
        ),
      ),
    [props.parameterValueDict, props.parameters],
  );

  const areParametersUngrouped = groupedParams.length === 1 && groupedParams[0][0] === '';

  return (
    <>
      {groupedParams
        .filter(
          ([groupName, _]) =>
            areParametersUngrouped ||
            !areAllGroupParametersHidden(props.instanceName, groupName),
        )
        .map(([groupName, parametersInGroup]) => {
          const onlyGroup = groupedParams.length === 1;
          const groupProps = {
            ...props,
            groupName,
            parameters: parametersInGroup,
            onlyGroup,
          };
          return (
            <ElementParameterGroup
              workflowId={props.workflowId}
              workflowName={props.workflowName}
              key={`param-group-${groupName}`}
              {...groupProps}
              isDisabled={props.isDisabled}
            />
          );
        })}
    </>
  );
}

type ElementParameterGroupProps = Props & {
  groupName: string;
  onlyGroup: boolean;
};

const ElementParameterGroup = React.memo(function ElementParameterGroup(
  props: ElementParameterGroupProps,
) {
  const classes = useStyles();
  const description = props.parameters[0]?.groupDescription;
  return (
    <>
      <ElementParameterGroupHeader name={props.groupName} onlyGroup={props.onlyGroup} />
      <div className={cx({ [classes.paramGroupInputs]: !!props.groupName })}>
        {description && (
          <div className={classes.parameterGroupDescription}>{description}</div>
        )}
        <ElementParameterList {...props} parameters={props.parameters} />
      </div>
    </>
  );
});

type ElementParameterListProps = Props;

export const ElementParameterList = React.memo(function ElementParameterList(
  props: ElementParameterListProps,
) {
  const { parameters, parameterValueDict, ...instanceParameterProps } = props;
  const { getStateForParameter } = useContext(ParameterStateContext);

  // We filter out some parameters that are managed by the plate contents editor
  const { plateContentParams, plateParameterFilter } = useFilterPlateContentParameters([
    ...props.parameters,
  ]);

  return (
    <>
      {parameters.filter(plateParameterFilter).map(parameter => (
        <HideableInstanceParameter
          key={parameter.name}
          parameter={parameter}
          // This is important: never pass valueDict that contains values for all parameters to
          // the component for a single parameter. That will force all parameter editors to rerender
          // when only single parameter value changes
          paramValue={parameterValueDict[parameter.name]}
          paramState={getStateForParameter(props.instanceName, parameter.name)}
          parameterValueDict={parameterValueDict}
          plateContentParams={plateContentParams}
          {...instanceParameterProps}
        />
      ))}
    </>
  );
});

type InstanceParameterProps = GeneralProps &
  Pick<Props, 'parameterValueDict' | 'showValidation'> & {
    parameter: Parameter;
    paramValue: ParameterValue;
    /** Properties of the parameter calculated by the rules engine. */
    paramState: ParameterState | undefined;
    workflowConfig: WorkflowConfig;
    plateContentParams?: PlateContentParams;
  };

const HideableInstanceParameter = React.memo(function HideableInstanceParameter(
  props: InstanceParameterProps,
) {
  const classes = useStyles();
  const { paramState, parameter } = props;

  const isPlateBasedMixing = useFeatureToggle('PLATE_BASED_MIXING');
  let isVisible = paramState?.isVisible ?? true;
  if (parameter.name === PLATE_BASED_MIXING_PARAMETER) {
    isVisible = isVisible && isPlateBasedMixing;
  }

  return (
    <Collapse in={isVisible}>
      <div className={classes.parameterContainer} key={`param-${parameter.name}`}>
        <InstanceParameter {...props} />
      </div>
    </Collapse>
  );
});

export function InstanceParameter({
  elementId,
  paramState,
  onChange,
  instanceName,
  onPendingChange,
  parameter,
  paramValue,
  showValidation,
  ...props
}: InstanceParameterProps) {
  const classes = useStyles();
  const dispatch = useWorkflowBuilderDispatch();
  const isElementConfigDebugModeEnabled = useFeatureToggle(
    'ELEMENT_CONFIGURATION_DEBUG_MODE',
  );
  const isEnabledElementParameterValidation = useFeatureToggle('PARAMETER_VALIDATION');

  const onChangeWithSanitise = useCallback(
    (newParamValue: any) => {
      const sanitisedNewValue = sanitiseParameterValue(newParamValue);
      onChange(parameter.name, sanitisedNewValue, instanceName);
    },
    [instanceName, onChange, parameter.name],
  );

  const onPendingChangeWithSanitise = useCallback(
    (newParamValue: any) => {
      const sanitisedNewValue = sanitiseParameterValue(newParamValue);
      onPendingChange?.(parameter.name, sanitisedNewValue, instanceName);
    },
    [instanceName, onPendingChange, parameter.name],
  );

  const anthaType = parameter.type;
  const displayName = getParameterDisplayName(parameter, isElementConfigDebugModeEnabled);
  const editorType = parameter.configuration?.editor.type;
  const placeholder = parameter.configuration?.editor.placeholder;

  // If an output from another element instance provides this parameter with a value
  // already, this will be a string label showing which output port that is.
  const outputProvidingValue = props.connectionMaskDict?.[parameter.name];

  const editorDisabled = paramState?.isEnabled === false || props.isDisabled;

  const editorProps = getParameterEditorProps(
    parameter,
    props.plateContentParams,
    props.parameterValueDict,
    (plateName: string) =>
      props.plateContentParams &&
      dispatch({
        type: 'deletePlateContentsEditorParameters',
        payload: {
          instanceName,
          plateContentParams: props.plateContentParams,
          selectedPlateName: plateName,
        },
      }),
  );

  const parameterNames = useMemo(
    () => ({
      name: parameter.name,
      displayName: parameter.configuration?.displayName,
    }),
    [parameter.configuration?.displayName, parameter.name],
  );

  let helperText: ParameterEditorHelperText | undefined = undefined;
  if (paramState?.errorMessages?.[0]) {
    helperText = { type: 'error', message: paramState.errorMessages[0] };
  } else if (paramState?.warningMessages?.[0]) {
    helperText = { type: 'warning', message: paramState.warningMessages[0] };
  }

  return (
    <>
      <div>
        <ParameterHeader
          elementId={elementId}
          name={parameter.name}
          displayName={displayName}
          isRequired={paramState?.isRequired}
          isValid={
            isEnabledElementParameterValidation && showValidation
              ? paramState?.isValid
              : true
          }
        />
        {parameter.configuration?.shortDescription && (
          <Typography variant="caption" className={classes.description}>
            {parameter.configuration?.shortDescription}
          </Typography>
        )}
      </div>
      {!outputProvidingValue ? (
        <ParameterEditor
          isDisabled={editorDisabled}
          anthaType={anthaType}
          parameter={parameterNames}
          value={paramValue}
          workflowId={props.workflowId}
          workflowName={props.workflowName}
          onChange={onChangeWithSanitise}
          onPendingChange={onPendingChangeWithSanitise}
          instanceName={instanceName}
          editorType={editorType}
          editorProps={editorProps ?? undefined}
          workflowConfig={props.workflowConfig}
          placeholder={placeholder}
          helperText={helperText}
        />
      ) : (
        <ConnectionOnlyEditor isDisabled value={outputProvidingValue} />
      )}
    </>
  );
}

/**
 * Returns the editor props for the given parameter.
 *
 * We handle two use cases specific to EditorType.PLATE_CONTENTS_SUMMARY and
 * EditorType.PLATE_LAYOUT_LAYERS, where we have to build some additional
 * custom props to deal with the functionality of these, and those props are
 * not supplied by element configurations.
 *
 */
export function getParameterEditorProps(
  parameter: Parameter,
  plateContentParams: PlateContentParams | undefined,
  parameterValueDict: ParameterValueDict,
  onDeletePlateContentsName: (name: string) => void,
): AdditionalEditorProps | null | undefined {
  const handleDeletePlateContentParams = (index: number) => {
    if (!plateContentParams) {
      return;
    }
    const allPlateNames = plateContentParams?.plateNameParam
      ? parameterValueDict[plateContentParams?.plateNameParam?.name]
      : [];
    if (allPlateNames[index]) {
      onDeletePlateContentsName(allPlateNames[index]);
    }
  };

  // For EditorType.PLATE_CONTENTS_SUMMARY we pass down a custom handler
  // that controls the deletion of plate entries, as well as passing confirmDelete as true.
  // We know we are dealing with the correct parameter by using the plateContentParams
  // props and checking the type.
  if (parameter.type === plateContentParams?.plateNameParam?.type) {
    return {
      editor: EditorType.ARRAY,
      itemEditor: {
        type: EditorType.PLATE_CONTENTS_SUMMARY,
      },
      onItemDelete: handleDeletePlateContentParams,
      confirmDeletion: true,
    } as ArrayAdditionalProps;
  }

  // For EditorType.PLATE_LAYOUT_LAYERS we want to set confirmDelete as true.
  if (
    parameter.configuration?.editor.type === EditorType.ARRAY &&
    parameter.configuration.editor.additionalProps
  ) {
    const arrayAdditionalProps = parameter.configuration.editor
      .additionalProps as ArrayAdditionalProps;
    if (arrayAdditionalProps.itemEditor?.type === EditorType.PLATE_LAYOUT_LAYERS) {
      return {
        ...arrayAdditionalProps,
        confirmDeletion: true,
      };
    }
  }

  return parameter.configuration?.editor.additionalProps;
}

const useStyles = makeStylesHook(({ palette, spacing }) => ({
  parameterContainer: {
    marginTop: '12px',
  },
  paramGroupInputs: {
    borderLeft: `2px solid ${palette.info.dark}`,
    paddingLeft: '8px',
  },
  description: {
    display: 'block',
    paddingBottom: spacing(2),
    color: Colors.TEXT_SECONDARY,
  },
  parameterGroupDescription: {
    backgroundColor: Colors.BLUE_5,
    color: palette.text.primary,

    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 400,

    borderRadius: '4px',
    padding: spacing(2, 3),
    marginTop: spacing(2),
  },
}));
