import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { usePlatesByType } from 'client/app/api/PlateTypesApi';
import AutocompleteWithParameterValues from 'client/app/components/Parameters/AutocompleteWithParameterValues';
import {
  getPlateContentParams,
  PlateContentParams,
} from 'client/app/components/Parameters/PlateContents/lib/plateContentsEditorUtils';
import {
  REPLICATE_GROUPING_PARAMETER_NAME,
  REPLICATE_GROUPING_PARAMETER_NAME_VALUE,
} from 'client/app/components/Parameters/PlateContents/PlateContentsEditorContents';
import { ParameterStateContext } from 'client/app/lib/rules/elementConfiguration/ParameterStateContext';
import {
  useWorkflowBuilderDispatch,
  useWorkflowBuilderSelector,
} from 'client/app/state/WorkflowBuilderStateContext';
import { getArrayTypeFromAnthaType } from 'common/elementConfiguration/parameterUtils';
import { isDefined } from 'common/lib/data';
import { ParameterValueDict } from 'common/types/bundle';
import { PlateType } from 'common/types/plateType';
import Colors from 'common/ui/Colors';
import Button from 'common/ui/components/Button';
import { ParameterEditorBaseProps } from 'common/ui/components/ParameterEditorBaseProps';
import GenericInputEditor from 'common/ui/components/ParameterEditors/GenericInputEditor';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useDebounce from 'common/ui/hooks/useDebounce';

const TEXT_EDIT_DEBOUNCE_MS = 150;

type Props = {
  instanceName: string | undefined;
  onChange: (newValue: string) => void;
} & ParameterEditorBaseProps<string>;

/**
 * Used for controlling the display of the PlateContentsEditorPanel and the
 * plate names associated with the parameters.
 *
 * Displays an input field and a button.
 *
 * The input is used to modify the plate names (i.e. the plateContentNameParam).
 *
 * The button is used to control the opening/closing of the panel, with the button
 * copy displaying information about the particular plate name and contents.
 */
export function PlateContentsSummaryParameter(props: Props) {
  const classes = useStyles();
  const dispatch = useWorkflowBuilderDispatch();

  const { value: plateName, instanceName, onChange } = props;

  const { parameters, element, elementInstance, additionalPanel, selectedPlateName } =
    useWorkflowBuilderSelector(state => {
      const { elementInstances, elementSet, additionalPanel, plateEditorPanelProps } =
        state;
      const elementInstance = elementInstances.find(ei => ei.name === instanceName);
      const element = elementSet?.elements.find(
        element => element.id === elementInstance?.element.id,
      );

      return {
        parameters: state.parameters,
        element,
        elementInstance,
        additionalPanel,
        selectedPlateName: plateEditorPanelProps.plateName,
      };
    });

  const [plateTypes, plateTypesLoading] = usePlatesByType();
  const plateContentParams = useMemo<PlateContentParams | undefined>(() => {
    return element?.inputs ? getPlateContentParams(element?.inputs) : undefined;
  }, [element?.inputs]);

  if (!plateContentParams || !elementInstance) {
    throw new Error(
      'Plate contents editor cannot be used without plate content parameters or element instance',
    );
  }

  const paramValues = useMemo<ParameterValueDict>(
    () => parameters[elementInstance.name] ?? {},
    [elementInstance.name, parameters],
  );

  const contentsByWell = useMemo<ParameterValueDict>(
    () =>
      (plateName &&
        paramValues?.[plateContentParams.contentLocationParam.name]?.[plateName]) ??
      {},
    [paramValues, plateContentParams.contentLocationParam.name, plateName],
  );

  const allPlateNames = useMemo<string[]>(
    () =>
      plateContentParams?.plateNameParam?.name
        ? paramValues[plateContentParams.plateNameParam.name]
        : [],
    [paramValues, plateContentParams?.plateNameParam],
  );

  const onClick = useCallback(() => {
    dispatch({
      type: 'setPlateContentsEditorPanel',
      payload: {
        open: true,
        plateNameIndex: plateName ? allPlateNames.indexOf(plateName) : undefined,
        instanceName: props.instanceName,
        plateName: plateName,
      },
    });
  }, [allPlateNames, dispatch, plateName, props.instanceName]);

  const plateType = useMemo<PlateType | undefined>(() => {
    const plateTypesMap: Record<string, string> | undefined =
      plateContentParams.plateTypeParam &&
      paramValues[plateContentParams?.plateTypeParam.name];
    const plateTypeNameWithRiser = plateName && plateTypesMap?.[plateName];
    return plateTypeNameWithRiser ? plateTypes(plateTypeNameWithRiser) : undefined;
  }, [paramValues, plateContentParams.plateTypeParam, plateName, plateTypes]);

  const numberOfWellsDefined = useMemo<number>(() => {
    return !plateName
      ? 0
      : Object.keys(contentsByWell).flatMap(liquid => contentsByWell[liquid]).length;
  }, [contentsByWell, plateName]);

  const { getStateForParameter } = useContext(ParameterStateContext);

  /**
   * There is no point enabling the panel if all the platecontentparams
   * are hidden via rules. This happens in element configs if the plate layout
   * is wired from an upstream element. In these cases, we don't need to
   * show the panel, but should still allow the editing of the plateNameParam
   * type to allow the element to correctly index plates from the upstream element.
   */
  const areAllParametersHidden = useMemo<boolean>(() => {
    return plateContentParams.contentPropertyParams?.every(param => {
      const paramState = getStateForParameter(elementInstance.name, param.name);
      return paramState ? !paramState.isVisible : false;
    });
  }, [
    elementInstance.name,
    getStateForParameter,
    plateContentParams.contentPropertyParams,
  ]);

  const areAllRequiredParametersDefined = useMemo<boolean>(() => {
    if (!plateName || !plateType || numberOfWellsDefined === 0) {
      // No need to check if the user hasn't specifed these yet.
      return true;
    }
    return plateContentParams.contentPropertyParams?.every(param => {
      const paramState = getStateForParameter(elementInstance.name, param.name);
      if (!paramState?.isRequired) {
        return true;
      }
      if (!paramValues[param.name]) {
        return false;
      }
      const parameterValues = paramValues[param.name][plateName];
      return Object.keys(parameterValues).every(key => isDefined(parameterValues[key]));
    });
  }, [
    elementInstance.name,
    getStateForParameter,
    numberOfWellsDefined,
    paramValues,
    plateContentParams.contentPropertyParams,
    plateName,
    plateType,
  ]);

  const buttonCopy = useMemo<string>(() => {
    if (!plateType || !plateName) {
      return 'Edit plate contents';
    }
    if (
      paramValues[REPLICATE_GROUPING_PARAMETER_NAME] ===
      REPLICATE_GROUPING_PARAMETER_NAME_VALUE
    ) {
      return `${plateType.name}, all wells being optimized by Synthace`;
    }
    if (!areAllRequiredParametersDefined) {
      return 'Plate contents incomplete';
    }
    return `${plateType.name}, ${numberOfWellsDefined} wells defined`;
  }, [
    plateType,
    plateName,
    paramValues,
    areAllRequiredParametersDefined,
    numberOfWellsDefined,
  ]);

  // We don't wan't to save the name if it already exists as a plateName, so we store the input here
  // and run validation before saving. This is mainly to allow us to always show the updated input text
  // in GenericInputEditor.
  const [temporaryPlateNameValue, setTemporaryPlateNameValue] = useState(plateName);
  useEffect(() => {
    setTemporaryPlateNameValue(plateName);
  }, [plateName]);

  const [errorMessage, setErrorMessage] = useState('');

  const debouncePlateNameSave = useDebounce(() => {
    const newPlateName = temporaryPlateNameValue ?? '';

    let errorMessage = '';
    if (allPlateNames.includes(newPlateName) && newPlateName !== plateName) {
      errorMessage = `Sorry, "${newPlateName}" already exists.`;
    }
    if (newPlateName === '') {
      errorMessage = `Sorry, the plate name cannot be empty.`;
    }
    if (newPlateName !== newPlateName.trim()) {
      errorMessage = 'Sorry, plate name cannot start or end with a space.';
    }

    setErrorMessage(errorMessage);

    if (errorMessage) {
      return;
    }
    if (newPlateName !== plateName) {
      dispatch({
        type: 'updatePlateContentsEditorParameters',
        payload: {
          instanceName: elementInstance.name,
          plateContentParams,
          selectedPlateName: plateName,
          replacementPlateName: newPlateName,
        },
      });
    }
    onChange(newPlateName);
  }, TEXT_EDIT_DEBOUNCE_MS);

  const handlePlateNameChange = useCallback(
    (value?: string) => {
      const newPlateName = value ?? '';
      setTemporaryPlateNameValue(newPlateName);
      debouncePlateNameSave();
    },
    [debouncePlateNameSave],
  );

  return (
    <div className={classes.container}>
      {areAllParametersHidden ? (
        // If all parameters hidden, we let the user pick from plate names already set in the workflow.
        <AutocompleteWithParameterValues
          anthaType={getArrayTypeFromAnthaType(
            plateContentParams.plateNameParam?.type ?? '',
          )}
          valueLabel={temporaryPlateNameValue}
          acceptCustomValues
          disableClearable
          onChange={handlePlateNameChange}
          isDisabled={props.isDisabled}
          hasError={!!errorMessage}
          placeholder="Enter a plate name..."
        />
      ) : (
        <GenericInputEditor
          type={plateContentParams.plateNameParam?.type ?? ''}
          value={temporaryPlateNameValue}
          onChange={handlePlateNameChange}
          isDisabled={props.isDisabled}
          hasError={!!errorMessage}
          placeholder="Enter a plate name..."
        />
      )}
      {errorMessage && <em className={classes.inlineError}>{errorMessage}</em>}
      {!areAllParametersHidden && (
        <EditButton
          active={
            additionalPanel === 'PlateContentsEditor' && plateName === selectedPlateName
          }
          error={!areAllRequiredParametersDefined}
          variant="secondary"
          onClick={onClick}
          disabled={!plateName || !!errorMessage || plateTypesLoading}
        >
          {plateTypesLoading ? (
            <CircularProgress className={classes.circularProgress} size={16} />
          ) : (
            <Typography className={classes.buttonText} variant="caption">
              {buttonCopy}
            </Typography>
          )}
        </EditButton>
      )}
    </div>
  );
}

const EditButton = styled(Button, {
  shouldForwardProp: prop => prop !== 'active' && prop !== 'error',
})<{
  active: boolean;
  error: boolean;
}>(({ active, error, theme }) => ({
  padding: theme.spacing(3, 4),
  background: active ? Colors.BLUE_5 : undefined,
  borderColor: active ? theme.palette.primary.main : undefined,
  color: error
    ? theme.palette.error.main
    : active
    ? theme.palette.primary.main
    : undefined,
}));

const useStyles = makeStylesHook(theme => ({
  buttonText: {
    marginRight: 'auto',
    textTransform: 'none',
    textAlign: 'left',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  inlineError: {
    color: Colors.RED,
    fontStyle: 'italic',
    fontSize: '11px',
  },
  circularProgress: {
    color: 'inherit',
  },
  active: {
    background: Colors.BLUE_5,
    borderColor: theme.palette.primary.main,
  },
}));
