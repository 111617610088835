import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import HelpIcon from '@mui/icons-material/HelpOutlineOutlined';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import cx from 'classnames';
import { produce } from 'immer';
import groupBy from 'lodash/groupBy';
import isEqual from 'lodash/isEqual';
import xor from 'lodash/xor';

import { usePlatesByType } from 'client/app/api/PlateTypesApi';
import isWorkflowReadonly from 'client/app/apps/workflow-builder/lib/isWorkflowReadonly';
import ElementParameterHelpIcon from 'client/app/components/Parameters/ElementParameterHelpIcon';
import {
  generateWellsBasedOnPattern,
  WELL_ITERATION_ORDER_PARAMETER_NAME,
  WELL_ITERATION_PATTERN_PARAMETER_NAME,
  WellIterationOrder,
  WellIterationPattern,
} from 'client/app/components/Parameters/PlateContents/lib/generateWellIterationUtils';
import {
  ContentsByWell,
  contentsByWellsToParamValues,
  cropContentsByWellToPlate,
  generatePlateContentParams,
  getAllLiquidIdentifierNames,
  getPlateContentParams,
  getWellGroupID,
  getWellGroupTitle,
  paramValuesToContentsByWell,
  PlateContentParams,
} from 'client/app/components/Parameters/PlateContents/lib/plateContentsEditorUtils';
import PlateContentsWellSelector, {
  EmptyPlateContentsWellSelector,
} from 'client/app/components/Parameters/PlateContents/PlateContentsWellSelector';
import WellGroupList, {
  WellGroup,
} from 'client/app/components/Parameters/PlateContents/WellGroupList';
import WellParameters from 'client/app/components/Parameters/PlateContents/WellParameters';
import PlateSelectionEditor from 'client/app/components/Parameters/PlateType/PlateSelectionEditor';
import { PlateTypeSelect } from 'client/app/components/Parameters/PlateType/PlateTypeSelect';
import { PlateParameterValue } from 'client/app/components/Parameters/PlateType/processPlateParameterValue';
import { reportError } from 'client/app/lib/errors';
import { ParameterStateContext } from 'client/app/lib/rules/elementConfiguration/ParameterStateContext';
import {
  useWorkflowBuilderDispatch,
  useWorkflowBuilderSelector,
} from 'client/app/state/WorkflowBuilderStateContext';
import { isDefined } from 'common/lib/data';
import doNothing from 'common/lib/doNothing';
import { cropWellsToExistingLocations, formatWellPosition } from 'common/lib/format';
import { alphanumericCompare } from 'common/lib/strings';
import { getFirstValue } from 'common/object';
import { ParameterValueDict } from 'common/types/bundle';
import { PlateType } from 'common/types/plateType';
import Colors from 'common/ui/Colors';
import Button from 'common/ui/components/Button';
import ConfirmationDialog from 'common/ui/components/Dialog/ConfirmationDialog';
import IconButtonWithPopper from 'common/ui/components/IconButtonWithPopper';
import { PopoverSection } from 'common/ui/components/Popover';
import LiquidColors from 'common/ui/components/simulation-details/LiquidColors';
import { WellLabelContent } from 'common/ui/components/simulation-details/mix/WellLabel';
import { WellTooltipTitleProps } from 'common/ui/components/simulation-details/mix/WellTooltip';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';
import TypographyWithTooltip from 'common/ui/components/TypographyWithTooltip';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useDialog from 'common/ui/hooks/useDialog';

// We don't want the wellSelector (and plate) to shrink down so much that it is so small to use.
// These values are the minimum dimensions associated with some of our common plates
// in SBS format, so should be generalizable enough for us to set here to ensure we don't shrink
// the plate too much.
const MINIMUM_PLATE_WIDTH = 383;
const MINIMUM_PLATE_HEIGHT = 256;

// Width of the plate contents list.
const CONTENTS_LIST_WIDTH = 252;

// If the panel is minimized we want to wrap the contents.
const PANEL_WRAP_WIDTH = MINIMUM_PLATE_WIDTH + CONTENTS_LIST_WIDTH;

// Using these parameter names directly is very fragile, but we don't have a way
// currently to target individial parameters and values in a nicer way.
export const REPLICATE_GROUPING_PARAMETER_NAME = 'ReplicateGrouping';
export const REPLICATE_GROUPING_PARAMETER_NAME_VALUE = 'Let Antha Optimize';

type PlateContentsEditorPanelProps = {
  onClose?: () => void;
  /**
   * Used to detect client width to adjust content wrap.
   */
  containerRef: React.RefObject<HTMLDivElement>;
};

export default function PlateContentsEditorContents({
  onClose,
  containerRef,
}: PlateContentsEditorPanelProps) {
  const classes = useStyles();
  const dispatch = useWorkflowBuilderDispatch();
  const snackbar = useSnackbarManager();
  const {
    elementInstance,
    element,
    parameters,
    workflowConfig,
    isReadonly,
    activePlateName,
  } = useWorkflowBuilderSelector(state => {
    const {
      elementInstances,
      parameters,
      config: workflowConfig,
      elementSet,
      plateEditorPanelProps,
    } = state;

    const elementInstance = elementInstances.find(
      ei => ei.name === plateEditorPanelProps.instanceName,
    );

    const element = elementSet?.elements.find(
      element => element.id === elementInstance?.element.id,
    );

    return {
      elementInstance,
      element,
      parameters,
      workflowConfig,
      isReadonly: isWorkflowReadonly(state.editMode, state.source),
      activePlateName: plateEditorPanelProps?.plateName,
    };
  });

  if (!element || !elementInstance) {
    // This shouldn't happen as the user shouldn't be able to open this Panel without selecting an element
    // but we require these to be defined.
    throw new Error(
      'Plate contents editor panel cannot be used without selecting an element.',
    );
  }

  const plateContentParams = useMemo<PlateContentParams | undefined>(
    () => getPlateContentParams(element.inputs),
    [element.inputs],
  );

  if (!plateContentParams) {
    // This shouldn't happen as the user should not have been able to open this panel without us checking if plateContentParams
    // already exist in the ElementParameterGroupList.
    throw new Error(
      'Plate contents editor panel could not be used as no plate content parameters were found in the element.',
    );
  }

  const paramValues = useMemo<ParameterValueDict>(
    () => parameters[elementInstance.name] ?? {},
    [elementInstance.name, parameters],
  );

  const plateNames = useMemo<string[]>(() => {
    return plateContentParams.plateNameParam &&
      Array.isArray(paramValues[plateContentParams.plateNameParam.name])
      ? paramValues[plateContentParams.plateNameParam.name]
      : [];
  }, [paramValues, plateContentParams.plateNameParam]);

  const [selectedWells, setSelectedWells] = useState<string[]>([]);
  const plateName = useMemo(
    () => activePlateName ?? plateNames?.[0],
    [activePlateName, plateNames],
  );

  const [plateTypes] = usePlatesByType();

  const plateTypeNameWithRiser = useMemo<string | null>(() => {
    if (!plateName) {
      return null;
    }
    const plateTypesMap: Record<string, string> | undefined =
      plateContentParams.plateTypeParam &&
      paramValues[plateContentParams?.plateTypeParam.name];
    return plateTypesMap?.[plateName] ?? null;
  }, [paramValues, plateContentParams.plateTypeParam, plateName]);

  const plateType = useMemo<PlateType | undefined>(() => {
    return plateTypeNameWithRiser ? plateTypes(plateTypeNameWithRiser) : undefined;
  }, [plateTypeNameWithRiser, plateTypes]);

  const numberOfWellsOnPlate = plateType ? plateType.columns * plateType.rows : 0;

  // Used to track the initial well layout for the purpose of resetting to this
  // if the user selected 'As Selected' patterns.
  const [initialWellLayoutPerLiquid, setInitialWellLayoutPerLiquid] = useState<{
    [liquidName: string]: string[];
  }>(paramValues?.[plateContentParams.contentLocationParam.name]?.[plateName] ?? {});

  const handleContentsChange = useCallback(
    (newContentsByWell: ContentsByWell) => {
      let convertedParamValues = contentsByWellsToParamValues(
        newContentsByWell,
        plateContentParams,
        paramValues,
        plateName,
      );
      try {
        if (plateName) {
          // WellLayout is located at the contentLocationParam.name
          const newLayoutByLiquid = convertedParamValues?.[
            plateContentParams.contentLocationParam.name
          ][plateName] as { [liquidName: string]: string[] };

          if (newLayoutByLiquid) {
            // We need the previous values for some of the parameters to allow us to do
            // comparisons with the new values.
            const previousLayoutByLiquid = paramValues?.[
              plateContentParams.contentLocationParam.name
            ]?.[plateName] as { [liquidName: string]: string[] };
            const previousWellPatternByLiquid =
              paramValues?.[WELL_ITERATION_PATTERN_PARAMETER_NAME]?.[plateName];

            const newIterationOrderByLiquid =
              convertedParamValues?.[WELL_ITERATION_ORDER_PARAMETER_NAME][plateName];
            const newWellPatternByLiquid =
              convertedParamValues?.[WELL_ITERATION_PATTERN_PARAMETER_NAME][plateName];

            const updatedWellLayout: { [liquidIdentifier: string]: string[] } = {};
            const updatedWellPatternByLiquid: {
              [liquidIdentifier: string]: WellIterationPattern;
            } = {};
            const updatedWellIterationOrderByLiquid: {
              [liquidIdentifier: string]: WellIterationOrder;
            } = {};

            const { previousName, updatedName } = findUpdatedLiquidName(
              previousLayoutByLiquid,
              newLayoutByLiquid,
            );

            const initialWellLayoutPerLiquidCopy = { ...initialWellLayoutPerLiquid };

            if (previousName && updatedName) {
              initialWellLayoutPerLiquidCopy[updatedName] =
                initialWellLayoutPerLiquidCopy[previousName];
              delete initialWellLayoutPerLiquidCopy[previousName];
            }

            for (const liquid of Object.keys(newLayoutByLiquid)) {
              const previousLayout = previousLayoutByLiquid?.[liquid];
              const previousPattern = previousWellPatternByLiquid?.[liquid];
              const newLayout = newLayoutByLiquid[liquid];
              const newPattern = newWellPatternByLiquid[liquid] as WellIterationPattern;
              const newOrder = newIterationOrderByLiquid[liquid] as WellIterationOrder;

              if (newPattern === 'As Selected' && newOrder !== 'As Selected') {
                updatedWellIterationOrderByLiquid[liquid] = 'As Selected';
              } else {
                updatedWellIterationOrderByLiquid[liquid] = newOrder;
              }

              if (!initialWellLayoutPerLiquidCopy?.[liquid]) {
                initialWellLayoutPerLiquidCopy[liquid] = newLayout;
              }

              if (
                hasNewLiquidBeenAdded(previousLayoutByLiquid, newLayoutByLiquid) &&
                !previousLayoutByLiquid?.[liquid]
              ) {
                // If the user has added a new liquid, always default to these for pattern and
                // iteration order. We should not reset the selected wells here.
                updatedWellPatternByLiquid[liquid] = 'As Selected';
                updatedWellIterationOrderByLiquid[liquid] = 'As Selected';
                updatedWellLayout[liquid] = newLayout;
              } else if (
                newPattern === 'As Selected' &&
                previousLayout &&
                previousLayout.length === newLayout.length &&
                previousPattern !== 'As Selected'
              ) {
                // If the user has selected 'As Selected', the intention is to reset it to the initial layout.
                // If we don't have that yet, set it to the new layout.
                const result = (updatedWellLayout[liquid] =
                  initialWellLayoutPerLiquidCopy?.[liquid] ?? newLayout);
                updatedWellPatternByLiquid[liquid] = 'As Selected';
                setSelectedWells(result);
              } else {
                const result = generateWellsBasedOnPattern(
                  newLayout,
                  newPattern,
                  newOrder,
                  numberOfWellsOnPlate,
                );
                updatedWellLayout[liquid] = result;
                updatedWellPatternByLiquid[liquid] = newPattern;
                // Update the selected wells if there are changes to the well ordering.
                if (isEqual([...selectedWells].sort(), [...result].sort())) {
                  setSelectedWells(result);
                }
              }
            }

            setInitialWellLayoutPerLiquid(initialWellLayoutPerLiquidCopy);
            convertedParamValues = produce(convertedParamValues, draft => {
              draft[plateContentParams.contentLocationParam.name][plateName] =
                updatedWellLayout;
              draft[WELL_ITERATION_PATTERN_PARAMETER_NAME][plateName] =
                updatedWellPatternByLiquid;
              draft[WELL_ITERATION_ORDER_PARAMETER_NAME][plateName] =
                updatedWellIterationOrderByLiquid;
            });
          }
        }

        dispatch({
          type: 'updateAllParameters',
          payload: {
            instanceName: elementInstance?.name,
            parameterValues: { ...paramValues, ...convertedParamValues },
          },
        });
      } catch (err) {
        reportError(err);
        if (err instanceof Error) {
          snackbar.showError(err.message);
        }
      }
    },
    [
      dispatch,
      elementInstance?.name,
      initialWellLayoutPerLiquid,
      numberOfWellsOnPlate,
      paramValues,
      plateContentParams,
      plateName,
      selectedWells,
      snackbar,
    ],
  );

  const handlePlateSelectionChange = useCallback(
    (value?: PlateParameterValue) => {
      if (plateContentParams.plateTypeParam && plateName) {
        // If the plate has been cleared, the value will be falsy.
        // In this case, we want to remove any liquids that are associated with
        // the current plateName, and also remove the field for the plateName
        // from the plateTypeParam.
        let contentsByWellForPlate = value
          ? paramValuesToContentsByWell(paramValues, plateContentParams, plateName)
          : new Map<string, ParameterValueDict>(); // Empty map so we create params with no wells selected;

        if (value && typeof value === 'string') {
          contentsByWellForPlate = cropContentsByWellToPlate(
            contentsByWellForPlate,
            plateTypes(value),
          );
        }

        const convertedParamValues = contentsByWellsToParamValues(
          contentsByWellForPlate,
          plateContentParams,
          paramValues,
          plateName,
        );

        let plateParamValues = paramValues[plateContentParams.plateTypeParam.name];
        if (value) {
          plateParamValues = {
            ...plateParamValues,
            [plateName]: value,
          };
        } else {
          // The plate has been deleted so remove all parameter values.
          // TODO - Add a confirmation dialog for the user in this case.
          const { [plateName]: _, ...rest } = plateParamValues;
          plateParamValues = rest;
        }
        const updatedParamValues = {
          ...paramValues,
          ...convertedParamValues,
          [plateContentParams.plateTypeParam.name]: plateParamValues,
        };
        dispatch({
          type: 'updateAllParameters',
          payload: {
            instanceName: elementInstance?.name,
            parameterValues: updatedParamValues,
          },
        });
      }
    },
    [
      dispatch,
      elementInstance?.name,
      paramValues,
      plateContentParams,
      plateName,
      plateTypes,
    ],
  );

  const contentsByWell = useMemo<ContentsByWell>(
    () => paramValuesToContentsByWell(paramValues, plateContentParams, plateName),
    [paramValues, plateContentParams, plateName],
  );

  // Get the selected well addresses which have no well contents
  const selectedEmptyWells = useMemo<string[]>(
    () => selectedWells.filter(wellLocation => !contentsByWell.has(wellLocation)),
    [contentsByWell, selectedWells],
  );

  const wellPreferenceOrder = useMemo(() => {
    if (
      !plateName ||
      !paramValues[plateContentParams.contentLocationParam.name]?.[plateName]
    ) {
      return {};
    }
    const wellPreferenceOrder: Record<string, number> = {};
    Object.values(
      paramValues[plateContentParams.contentLocationParam.name][plateName],
    ).forEach(wells => {
      if (!Array.isArray(wells)) {
        return;
      }
      wells.forEach(well => {
        wellPreferenceOrder[well] = wells.indexOf(well);
      });
    });
    return wellPreferenceOrder;
  }, [paramValues, plateContentParams.contentLocationParam.name, plateName]);

  const getContentLabel = useCallback(
    (well: string) => {
      let heading = '';

      const selectedIndex = selectedWells.indexOf(well);

      if (selectedIndex > -1) {
        heading = `${selectedIndex + 1}`;

        // The value can be 0 which will evaluate to falsey, so we have the extra
        // check here to allow that condition to pass through
      } else if (wellPreferenceOrder?.[well] || wellPreferenceOrder?.[well] === 0) {
        heading = (wellPreferenceOrder[well] + 1).toString();
      }

      return { type: 'ContentLabel', heading } as WellLabelContent;
    },
    [selectedWells, wellPreferenceOrder],
  );

  const TooltipTitle = useCallback(
    (props: WellTooltipTitleProps) => {
      if (props.wellContents) {
        const wellLocation = formatWellPosition(props.wellLocationOnDeckItem);
        const selectedWellIndex = selectedWells.indexOf(wellLocation);
        const plateEditorContents = contentsByWell.get(wellLocation);
        return (
          <div className={classes.wellTooltipTitle}>
            <PopoverSection header="Location" text={wellLocation} />
            <PopoverSection
              header="Iteration order #"
              text={getContentLabel(wellLocation).heading}
            />
            {selectedWellIndex >= 0 && (
              <PopoverSection
                header="Selection order #"
                text={`${selectedWellIndex + 1}/${selectedWells.length}`}
              />
            )}

            <PopoverSection
              header="Liquid"
              // For the liquid name, we don't want to use the name from the props (i.e. WellContents) because this is
              // the well group id (which is a concatenation of the liquid name and other properties).
              // We can use the liquid name as per the contentsByWell, which is the actual liquid name.
              text={
                plateEditorContents?.[plateContentParams.contentLocationParam.name] ?? ''
              }
            />
            <PopoverSection
              header="Iteration order"
              text={plateEditorContents?.[WELL_ITERATION_ORDER_PARAMETER_NAME] ?? ''}
            />
            <PopoverSection
              header="Iteration pattern"
              text={plateEditorContents?.[WELL_ITERATION_PATTERN_PARAMETER_NAME] ?? ''}
            />
          </div>
        );
      }
      return <PopoverSection text="No liquid allocated" />;
    },
    [
      classes.wellTooltipTitle,
      contentsByWell,
      getContentLabel,
      plateContentParams.contentLocationParam.name,
      selectedWells,
    ],
  );

  const liquidColors = useMemo(() => LiquidColors.createAvoidingAllColorCollisions(), []);

  // If the user changes the plate type, deselect wells that don't exist on the new plate
  useEffect(() => {
    setSelectedWells(selectedWells =>
      plateType ? cropWellsToExistingLocations(selectedWells, plateType) : [],
    );
  }, [plateType]);

  const [editingGroup, setEditingGroup] = useState<WellGroup | undefined>();
  const handleSetEditingGroup = useCallback((group: WellGroup | undefined) => {
    setEditingGroup(group);
  }, []);

  useEffect(() => {
    if (!editingGroup) {
      setInitialWellLayoutPerLiquid(
        paramValues?.[plateContentParams.contentLocationParam.name]?.[plateName] ?? {},
      );
    }
  }, [
    editingGroup,
    paramValues,
    plateContentParams.contentLocationParam.name,
    plateName,
  ]);

  const { getStateForParameter } = useContext(ParameterStateContext);
  const [confirmDoneDialog, openConfirmDoneDialog] = useDialog(ConfirmationDialog);

  const handleClose = useCallback(async () => {
    if (editingGroup) {
      const parameters = generatePlateContentParams(plateContentParams);
      const groupParamValues: ParameterValueDict = {
        ...getFirstValue(editingGroup.contentsByWell),
      };
      const areAllRequiredParametersDefined = parameters.every(param => {
        const paramState = getStateForParameter(elementInstance.name, param.name);
        return paramState?.isRequired ? isDefined(groupParamValues[param.name]) : true;
      });

      if (!areAllRequiredParametersDefined) {
        const isConfirmed = await openConfirmDoneDialog({
          action: 'close',
          isActionDestructive: true,
          object: 'plate contents editor',
          additionalMessage:
            'Not all required parameters are specified in the open liquid card.',
        });
        if (!isConfirmed) {
          return;
        }
      }
    }
    onClose?.();
  }, [
    editingGroup,
    elementInstance.name,
    getStateForParameter,
    onClose,
    openConfirmDoneDialog,
    plateContentParams,
  ]);

  const showEditorInEmptyDisabledState = useMemo(() => {
    return (
      paramValues[REPLICATE_GROUPING_PARAMETER_NAME] ===
      REPLICATE_GROUPING_PARAMETER_NAME_VALUE
    );
  }, [paramValues]);

  const handleSelectAll = useCallback(() => {
    if (!plateType) {
      return;
    }
    const allWells = [];
    for (let row = 0; row < plateType.rows; row++) {
      for (let column = 0; column < plateType.columns; column++) {
        allWells.push(formatWellPosition(row, column));
      }
    }
    setSelectedWells(allWells);
  }, [plateType]);

  const handleClearSelection = useCallback(() => {
    setSelectedWells([]);
  }, []);

  useEffect(() => {
    // If someone switched to another plate contents editor panel (i.e. the activePlateName
    // changes), then we must reset these to the intial state so the user can select again.
    handleSetEditingGroup(undefined);
    setSelectedWells([]);
  }, [activePlateName, handleSetEditingGroup]);

  // The 'group ID' determines the group that a well is within. We use the key of the well
  // locations param. For example, for a parameter with type map[LiquidName]WellLocations,
  // wells will be grouped by LiquidName.
  const getGroupID = useCallback(
    (wellParamValues?: ParameterValueDict): string =>
      getWellGroupID(plateContentParams, wellParamValues),
    [plateContentParams],
  );

  // The unique title is created using concatanation of parameters.
  // The color is created using just the name of the liquid.
  const getGroupProps = useCallback(
    (wellParamValues?: ParameterValueDict) => ({
      title: getWellGroupTitle(plateContentParams, wellParamValues),
      color: liquidColors.getColorFromLiquidString(
        wellParamValues?.[plateContentParams.contentLocationParam.name] ?? '',
        false,
      ),
    }),
    [liquidColors, plateContentParams],
  );

  // Generate contents for the selected wells. If `contentsToCopy` is provided
  // then those contents will be copied into the new wells.
  // Otherwise the new contents will be empty.
  const generateNewWellContents = useCallback(
    (wellLocations: string[], contentsToCopy?: ParameterValueDict): ContentsByWell => {
      return new Map(
        wellLocations.map(wellLocation => {
          return [wellLocation, { ...contentsToCopy }];
        }),
      );
    },
    [],
  );

  const [isPanelNarrowWidth, setIsPanelNarrowWidth] = useState(false);

  const onWindowResize = useCallback(() => {
    if (containerRef?.current) {
      setIsPanelNarrowWidth(containerRef.current.clientWidth < PANEL_WRAP_WIDTH);
    }
  }, [containerRef]);

  useEffect(() => {
    onWindowResize();
    window.addEventListener('resize', onWindowResize);
    return () => window.removeEventListener('resize', onWindowResize);
  }, [onWindowResize]);

  const allLiquidIdentifierNames = useMemo(() => {
    return getAllLiquidIdentifierNames(contentsByWell, plateContentParams);
  }, [contentsByWell, plateContentParams]);

  const [confirmDeleteAllDialog, openConfirmDeleteAllDialog] =
    useDialog(ConfirmationDialog);

  const handleDeleteAllWellSets = useCallback(async () => {
    const isConfirmed = await openConfirmDeleteAllDialog({
      action: 'delete',
      isActionDestructive: true,
      object: 'well sets',
    });
    if (!isConfirmed) {
      return;
    }
    handleContentsChange(new Map());
    handleSetEditingGroup(undefined);
    setSelectedWells([]);
  }, [handleContentsChange, handleSetEditingGroup, openConfirmDeleteAllDialog]);

  // Combines the plateName and selectedWells into a string to use as the WellGroup Id.
  const generateWellGroupId = (selectedWells: string[], plateName?: string) => {
    const sortedWells = [...selectedWells].sort(alphanumericCompare);
    return [plateName ?? '', ...sortedWells].join('');
  };

  const editingGroupId = editingGroup?.id ?? '';

  const [groups, setGroups] = useState<WellGroup[]>([]);

  // Re-group wells:
  // * on initialisation
  // * when well contents change
  // * when selected wells change (this handles case where cancel is pressed or
  //   user has selected something)
  useEffect(() => {
    const groups = Object.values(
      groupBy([...contentsByWell], ([_, wellContents]) => getGroupID(wellContents)),
    )
      // For each of the groups of wells, generate the group title, color, etc.
      .map((wellLocationContentPairs): WellGroup => {
        const groupContentsByWell = new Map(wellLocationContentPairs);
        const wellsInGroup = [...groupContentsByWell.keys()];
        // Get any well's contents from within the group (doesn't matter which,
        // since they should all be the same). This will be used to generate
        // title and color of the group.
        const wellContents = groupContentsByWell.get(wellsInGroup[0]);
        const { title, color } = getGroupProps(wellContents);

        // Generate a unique react key for this group by concatenating the plate
        // name and well locations within the group. This means that when the
        // content is changed, the list will re-render.
        //
        // We cannot use the title here, as we use this id to set the state of
        // editingGroupId, and because we allow users to edit the liquid name
        // (i.e. the title) then the id would change as the liquid name changed
        // (and therefore close the editing parameters list).
        //
        // We also concatenate each well location in the group, so that
        // when adding to the group the key will change and the list will update.
        const id = generateWellGroupId(wellsInGroup, plateName);

        return {
          id,
          contentsByWell: groupContentsByWell,
          color,
          title,
        };
      });

    // Create a group for the wells the user has selected such that
    // the user can add a group for those wells. The inputs
    // will be pre-filled with the output from generateWellContents.
    // We only allow users to add groups when there is at least one empty
    // well selected - but the group will span all the selected
    // wells (which could include wells from other groups, for example).
    if (selectedEmptyWells.length > 0 && (!isReadonly || !plateType)) {
      const id = generateWellGroupId(selectedWells, plateName);
      groups.unshift({
        ...getGroupProps(undefined),
        id,
        contentsByWell: generateNewWellContents(selectedWells, contentsByWell),
        isEmpty: id === editingGroupId ? false : true, // If the user has this group open (i.e. id === editingGroupId) we shouldn't set this to true, otherwise on re-render we will think this is an empty group and close the editing WellGroupListItem.
      });
    }

    setGroups(groups);

    // We need to keep the editing group updated if any contents change.
    if (editingGroup) {
      const group = groups.find(group => group.id === editingGroupId);
      if (group && !isEqual(editingGroup, group)) {
        handleSetEditingGroup(group);
      }
    }
  }, [
    contentsByWell,
    selectedWells,
    plateName,
    editingGroup,
    handleSetEditingGroup,
    getGroupID,
    getGroupProps,
    selectedEmptyWells,
    generateNewWellContents,
    isReadonly,
    plateType,
    editingGroupId,
  ]);

  const emptyGroup = useMemo(() => groups.find(group => group.isEmpty), [groups]);

  const handleAddLiquid = useCallback(() => {
    const updatedGroups = groups.map(group =>
      group.id === emptyGroup?.id ? { ...group, isEmpty: false } : group,
    );
    setGroups(updatedGroups);
    setEditingGroup(emptyGroup);
  }, [emptyGroup, groups]);

  if (!elementInstance || !element || !plateContentParams) {
    return null;
  }

  return (
    <>
      <TitleBar>
        <TypographyWithTooltip variant="h5">
          Plate Contents Editor: {plateName}
        </TypographyWithTooltip>
        {onClose && (
          <Button size="small" onClick={handleClose} variant="tertiary" color="primary">
            Done
          </Button>
        )}
      </TitleBar>
      <div className={cx(classes.container, { [classes.overflow]: isPanelNarrowWidth })}>
        {plateContentParams.plateNameParam &&
          plateContentParams.plateTypeParam &&
          plateNames && (
            <>
              <div className={classes.plateTypeSelector}>
                <Typography className={classes.plateTypeName} variant="subtitle2" noWrap>
                  Plate type
                </Typography>
                <PlateSelectionEditor
                  value={plateTypeNameWithRiser}
                  onChange={handlePlateSelectionChange}
                  isDisabled={isReadonly}
                  plateSelectors={[PlateTypeSelect]}
                />
                <ElementParameterHelpIcon
                  elementId={element.id}
                  name={plateContentParams.plateTypeParam.name}
                />
              </div>
              <Divider className={classes.divider} />
            </>
          )}
        <div
          className={cx(classes.wellSelectorAndPlateContentsContainer, {
            [classes.wrap]: isPanelNarrowWidth,
          })}
        >
          {plateType && (
            <div className={classes.wellSelectorContainer}>
              <div className={classes.wellSelectorActions}>
                <div className={classes.wellSelectorText}>
                  <Typography variant="body2" color="textPrimary">
                    Select wells below to create well set.
                  </Typography>
                  <IconButtonWithPopper
                    content={
                      <>
                        <Typography variant="caption">
                          The numbering of wells determines the order in which liquids (or
                          groups of liquids) will be arranged on the final plate.
                        </Typography>
                        <br />
                        <Typography variant="caption">
                          <strong>Note:&nbsp;</strong>this may be different to the order
                          in which Synthace dispenses liquids, which aims to maximise
                          multichannel pipetting.
                        </Typography>
                      </>
                    }
                    iconButtonProps={{
                      size: 'xsmall',
                      icon: <HelpIcon />,
                      color: 'inherit',
                    }}
                    overrideClassName={classes.helpIcon}
                    onClick={doNothing} //TODO: Update with logging
                  />
                </div>
                <div>
                  {selectedWells.length === 0 || !!editingGroup ? (
                    <Button
                      variant="secondary"
                      onClick={handleSelectAll}
                      disabled={!!editingGroup || showEditorInEmptyDisabledState}
                    >
                      Select All
                    </Button>
                  ) : (
                    <Button
                      variant="secondary"
                      onClick={handleClearSelection}
                      disabled={!!editingGroup || showEditorInEmptyDisabledState}
                    >
                      Clear All Selections
                    </Button>
                  )}
                  <Button
                    className={classes.addNewWellSetButton}
                    onClick={handleAddLiquid}
                    variant="secondary"
                    color="primary"
                    startIcon={<AddOutlinedIcon />}
                    disabled={!emptyGroup || isReadonly || showEditorInEmptyDisabledState}
                  >
                    Assign Liquid
                  </Button>
                </div>
              </div>
              <div className={classes.wellSelector}>
                {showEditorInEmptyDisabledState ? (
                  <EmptyPlateContentsWellSelector
                    plateType={plateType}
                    liquidColors={liquidColors}
                    isDisabled
                  />
                ) : (
                  <PlateContentsWellSelector
                    plateType={plateType}
                    selectedWells={selectedWells}
                    onSelectWells={setSelectedWells}
                    plateContentParams={plateContentParams}
                    contentsByWell={contentsByWell}
                    liquidColors={liquidColors}
                    getContentLabel={getContentLabel}
                    TooltipTitle={TooltipTitle}
                    isDisabled={!!editingGroup}
                  />
                )}
              </div>
            </div>
          )}
          <div
            className={cx(classes.plateContentsListContainer, {
              [classes.autoHeight]: isPanelNarrowWidth,
            })}
          >
            {!isReadonly && plateType && contentsByWell.size > 0 && !editingGroup && (
              <Button
                className={classes.deleteButton}
                variant="tertiary"
                onClick={handleDeleteAllWellSets}
                disabled={showEditorInEmptyDisabledState}
              >
                Delete all well sets
              </Button>
            )}
            {showEditorInEmptyDisabledState && plateType ? (
              <Typography color="textPrimary" variant="subtitle2">
                Allocation order is being optimized by Synthace so any available well will
                be used.
              </Typography>
            ) : (
              <WellGroupList
                groups={groups}
                contentsByWell={contentsByWell}
                selectedWells={selectedWells}
                selectedEmptyWells={selectedEmptyWells}
                isDisabled={isReadonly || !plateType}
                generateWellContents={generateNewWellContents}
                editingGroup={editingGroup}
                handleSetEditingGroup={group => handleSetEditingGroup(group)}
                wellParameters={group => (
                  <WellParameters
                    elementId={element.id}
                    instanceName={elementInstance.name}
                    plateContentParams={plateContentParams}
                    workflowConfig={workflowConfig}
                    contentsByWell={group.contentsByWell}
                    isParameterEditingDisabled={isReadonly}
                    // The boolean parameter of group.onChange determines if the Add button should
                    // be enabled. For now we don't do any validation, so we just pass true.
                    onGroupContentsChange={contentsByWell =>
                      group.onChange(contentsByWell, true)
                    }
                    canSaveParameters={group.canSaveParameters}
                    allLiquidIdentifierNames={allLiquidIdentifierNames}
                  />
                )}
                onChange={handleContentsChange}
                onSelectionChange={setSelectedWells}
                contentPropertyParams={plateContentParams.contentPropertyParams}
              />
            )}
          </div>
        </div>
      </div>
      {confirmDoneDialog}
      {confirmDeleteAllDialog}
    </>
  );
}

/**
 * Check if a new liquid has been added into newLayoutByLiquid that wasn't
 * already in previousLayoutByLiquid,
 */
function hasNewLiquidBeenAdded(
  previousLayoutByLiquid: { [liquidName: string]: string[] } | undefined,
  newLayoutByLiquid: { [liquidName: string]: string[] },
): boolean {
  const newLiquids = Object.keys(newLayoutByLiquid);
  const previousLiquids = Object.keys(previousLayoutByLiquid ?? {});
  // To know if a new liquid has been added, we can compare the length of the
  // newLayoutByLiquid to the length of the previousLayoutByLiquid.
  // newLayoutByLiquid should be 1 greater if a new liquid is added (as we have not
  // yet added that liquid to the workflow parameters).
  // previousLayoutByLiquid can be undefined (e.g. if this is the first liquid added).
  if (newLiquids.length > previousLiquids.length + 1) {
    return true;
  }
  // Otherwise, there is the chance that previousLiquids might have been removed when a
  // new liquid is added (i.e. on overwriting existing liquids with new ones, previousLiquids.length will
  // be less than newLiquids.length), so also check for this use case.
  return (
    newLiquids.filter(newLiquid => !previousLiquids.includes(newLiquid)).length === 1
  );
}

/**
 * If a liquidName has been updated between previousLayoutByLiquid and newLayoutByLiquid
 * (i.e. a liquidName is in newLayoutByLiquid, and not in previousLayoutByLiquid; and a liquidName
 * is in previousLayoutByLiquid, and not in  newLayoutByLiquid) we return the two names.
 * If no difference, we return empty strings.
 */
function findUpdatedLiquidName(
  previousLayoutByLiquid: { [liquidName: string]: string[] } | undefined,
  newLayoutByLiquid: { [liquidName: string]: string[] },
): { previousName: string; updatedName: string } {
  const previousLiquids = Object.keys(previousLayoutByLiquid ?? {});
  const newLiquids = Object.keys(newLayoutByLiquid);
  const diff = xor(previousLiquids, newLiquids);
  // The symmetric difference would return an array of length 2 if only 1 liquid name has been updated.
  // This will be in order of [previousName, updatedName].
  return diff.length === 2
    ? { previousName: diff[0], updatedName: diff[1] }
    : { previousName: '', updatedName: '' };
}

const TitleBar = styled('div')(({ theme }) => ({
  gridArea: 'titlebar',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(0, 3),
  borderBottom: `1px solid ${theme.palette.grey[200]}`,
  height: '50px',
}));

const useStyles = makeStylesHook(theme => ({
  titleBar: {
    gridArea: 'titlebar',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(0, 3),
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    height: '50px',
  },
  addNewWellSetButton: {
    marginLeft: theme.spacing(3),
  },
  deleteButton: {
    color: Colors.ERROR_MAIN,
    marginBottom: theme.spacing(5),
    marginLeft: 'auto',
  },
  divider: {
    margin: theme.spacing(5, 0),
  },
  plateTypeName: {
    '&:after': {
      fontSize: '1em',
      content: `'*'`,
      color: Colors.RED,
    },
    whiteSpace: 'nowrap',
  },
  container: {
    gridArea: 'main',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(3, 5, 5, 5),
  },
  helpIcon: {
    marginLeft: theme.spacing(3),
  },
  plateContentsListContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: `${CONTENTS_LIST_WIDTH}px`,
  },
  plateTypeSelector: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(3),
    width: '100%',
  },
  wellSelector: {
    minWidth: `${MINIMUM_PLATE_WIDTH}px`,
    minHeight: `${MINIMUM_PLATE_HEIGHT}px`,
  },
  wellSelectorContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  wellSelectorAndPlateContentsContainer: {
    display: 'flex',
    gap: theme.spacing(5),
    // We set minHeight here so this div takes up only the rest of the parent height.
    // Flex items by default will set the min-height to be the content, but
    // that won't work for us as we want the plate contents list to overflow
    // and the well selector to be contained within the panel - i.e. we don't want this
    // div to take up the height of the contents, but the height of the rest of the parent.
    minHeight: 0,
  },
  wellSelectorText: {
    display: 'flex',
    alignItems: 'center',
  },
  wellSelectorActions: {
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(3),
    justifyContent: 'space-between',
    marginBottom: theme.spacing(5),
  },
  wrap: {
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  autoHeight: {
    height: 'auto',
  },
  overflow: {
    overflowY: 'auto',
  },
  wellTooltipTitle: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
}));
