import React, { useCallback } from 'react';

import { styled } from '@mui/material/styles';

import { experimentsStyles } from 'client/app/apps/experiments/commonExperimentsStyles';
import { useDeleteProtocol } from 'client/app/apps/protocols/api/ProtocolsAPI';
import { ProtocolsDialog } from 'client/app/apps/protocols/Dialog';
import { EntityCardExpander } from 'client/app/apps/protocols/EntityCardExpander';
import { RecentProtocolInstancesFetcher } from 'client/app/apps/protocols/RecentProtocolInstanceList';
import { HasPermission } from 'client/app/components/Permissions';
import { Protocol, ProtocolsQuery } from 'client/app/gql';
import { protocolsRoutes } from 'client/app/lib/nav/actions';
import ConfirmationDialog from 'common/ui/components/Dialog/ConfirmationDialog';
import { EntityCard } from 'common/ui/components/EntityCard';
import EntityCardMenu from 'common/ui/components/EntityCardMenu';
import { useNavigation } from 'common/ui/components/navigation/useNavigation';
import useDialog from 'common/ui/hooks/useDialog';
import { ProtocolIcon } from 'common/ui/icons';

type Props = {
  data: ProtocolsQuery;
  currentUserId: string | undefined;
  onProtocolDetailsClick: (id: ProtocolId, version: ProtocolVersion) => void;
};

const ProtocolsListDataComponent = ({
  data,
  currentUserId,
  onProtocolDetailsClick,
}: Props) => {
  const [confirmationDialog, openConfirmationDialog] = useDialog(ConfirmationDialog);
  const [protocolDialog, openProtocolDialog] = useDialog(ProtocolsDialog);
  const protocols = data.protocols.items || [];
  const { handleDeleteProtocol } = useDeleteProtocol();
  const navigation = useNavigation();

  const handleMenuDeleteAction = useCallback(
    async (id: ProtocolId, version: ProtocolVersion, editVersion: number) => {
      const isDeleteConfirmed = await openConfirmationDialog({
        action: 'delete',
        isActionDestructive: true,
        object: 'protocol',
      });
      if (isDeleteConfirmed) {
        await handleDeleteProtocol(id, version, editVersion);
      }
    },
    [handleDeleteProtocol, openConfirmationDialog],
  );

  const handleDuplicateProtocol = async (protocol: Protocol) => {
    await openProtocolDialog({ protocol });
  };

  return (
    <>
      <List>
        {protocols.map(protocol => {
          return (
            <EntityCardExpander
              key={protocol.id}
              whenExpanded={<RecentProtocolInstancesFetcher protocolId={protocol.id} />}
            >
              <StyledEntityCard
                icon={<ProtocolIcon />}
                nameColumn={{ value: protocol.shortDescription, label: protocol.name }}
                authorColumn={{ value: protocol.createdBy.displayName, label: 'Author' }}
                interaction={{
                  onClick: () => handleDuplicateProtocol(protocol as Protocol),
                }}
                rightSlot={
                  <HasPermission
                    permission="delete:othersProtocol"
                    renderItem={hasPermission => {
                      const canDelete =
                        hasPermission || currentUserId === protocol.createdBy.id;
                      return (
                        <EntityCardMenu
                          menu={[
                            {
                              label: 'Duplicate',
                              onClick: () =>
                                navigation.navigate(protocolsRoutes.copyProtocol, {
                                  id: protocol.id,
                                  version: protocol.version,
                                }),
                            },
                            {
                              label: 'Delete',
                              disabled: !canDelete,
                              tooltip: canDelete
                                ? ''
                                : "Only admins may delete other user's Protocols",
                              onClick: () =>
                                handleMenuDeleteAction(
                                  protocol.id,
                                  protocol.version,
                                  protocol.editVersion,
                                ),
                            },
                            ...(protocol.isPublished === false
                              ? [
                                  {
                                    label: 'Edit Protocol',
                                    onClick: () =>
                                      onProtocolDetailsClick(
                                        protocol.id,
                                        protocol.version,
                                      ),
                                  },
                                ]
                              : []),
                          ]}
                        />
                      );
                    }}
                  />
                }
              />
            </EntityCardExpander>
          );
        })}
      </List>
      {confirmationDialog}
      {protocolDialog}
    </>
  );
};

const List = styled('div')(({ theme }) => ({
  ...experimentsStyles(theme).list,
}));

const StyledEntityCard = styled(EntityCard)(({ theme }) => ({
  border: 'none',
  '.EntityCardContent-nameColumn-label': {
    ...theme.typography.subtitle2,
  },
  '.EntityCardContent-nameColumn-value': {
    ...theme.typography.body1,
    color: theme.palette.text.secondary,
  },
}));

export default ProtocolsListDataComponent;
