import React from 'react';

import LinkOffIcon from '@mui/icons-material/LinkOff';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { Name } from 'client/app/apps/protocols/EditableName';
import Colors from 'common/ui/Colors';

type Props = {
  entry: string;
  linkedEntries: string[];
  onUnlink: () => void;
};

export function LinkedInputStepItem({ entry, linkedEntries, onUnlink }: Props) {
  return (
    <Stack>
      <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={5}>
        <Tooltip title="Unlink parameters">
          <StyledIconButton onClick={onUnlink}>
            <LinkOffIcon />
          </StyledIconButton>
        </Tooltip>
        <Name>{entry}</Name>
      </Stack>
      <VerticalLineStack
        alignItems="flex-start"
        marginLeft={9}
        paddingLeft={4}
        spacing={2}
      >
        {linkedEntries.map(value => (
          <Typography key={`${value}-linked`}>{value}</Typography>
        ))}
      </VerticalLineStack>
    </Stack>
  );
}

const StyledIconButton = styled(IconButton)(() => ({
  padding: 0,
  paddingLeft: 5,
}));

const VerticalLineStack = styled(Stack)(() => ({
  borderLeft: `2px solid ${Colors.GREY_30}`,
}));
