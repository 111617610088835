import React from 'react';

import { Name } from 'client/app/apps/protocols/EditableName';
import StepContents from 'client/app/apps/protocols/StepCard/StepContents';
import { ProtocolStepOutput } from 'common/types/Protocol';

type Props = {
  outputs: ProtocolStepOutput[];
  onDelete?: (contentIndex: number) => void;
};

export default function OutputStepContents(props: Props) {
  const { outputs, onDelete } = props;

  return (
    <StepContents
      items={outputs}
      getItemKey={({ id }) => id}
      renderItemContents={(output, _) => <Name>{output.displayName}</Name>}
      emptyMessage="Select an output from the elements in the workflow."
      onDelete={onDelete}
    />
  );
}
